export const messages = {
    
    en: {
        
        },
    sv:{
        article:{
            allergens: '<span>Allergisk? Prata med oss i restaurangen.</span>'
        },
        shopSelector: {
            singleShop: {
                start: "Beställ här",
                closed: "Stängt",
                paused: "Stängt för beställning",
                openingHoursHeader: "Dagens öppettider",
            }
        },
    },
    fi:{},
    no:{},
    ru:{}
}